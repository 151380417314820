import { PropertyGallery, PropertyManager } from "../structure/RenderService"

export const Private = () => {
     return (
      <div className="row">
        <div>
          <PropertyManager />
          <PropertyGallery />
        </div>
      </div>
    )
}