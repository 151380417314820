import { useEffect, useState } from 'react';
import { Dashboard } from '../structure/RenderDB';
import Spinner from 'react-bootstrap/Spinner';

function GrowComponent() {
  return <Spinner animation="grow" />;
}

export const Account = () => {
    const [showComponent, setShowComponent] = useState(false);
    
    const LoadingComponent = () =>{
        return(
            <div className='container w-100 h-100'>
                <div className='col'>
                    <GrowComponent size='l'/>
                </div>
            </div>
        )
    }

    useEffect(()=>{
        const timer = setTimeout(() => {
            setShowComponent(true);
        }, 3000);
      
        return () => clearTimeout(timer); 
    }, []);
    
    return (
        <div>
            {showComponent ? <Dashboard/> : <LoadingComponent />}
        </div>
    )
}