import { PrivacyData, ConditionData, processData, complaintsData } from "../../utils/company.data"

function MainItemsList({list}){
    return(
        <div>
            {list?.map((item, i)=>(
                <p className="p-2 lh-lg" key={i}>
                    {item}
                </p>
            ))}
        </div>
    )
}

function SecondaryItemsList({list}){
    return(
        <ul style={{
            listStyleType: 'circle'
        }}>
            {
                list.map((item, i)=>(
                    <li className="m-2" key={i}>
                        {item}
                    </li>
                ))
            }
        </ul>
    )
}

function PageFormatCard({title, subTitle, list, list2}){
    return(
        <div className="container">
            <h4 className="text-uppercase mb-2 p-2">
                {title}
            </h4>
            <MainItemsList list={list} />
            <h6 className="text-uppercase mb-2 p-2">
                {subTitle}
            </h6>
            <SecondaryItemsList list={list2} />
        </div>
    )
}

export const PrivacyPolicy = () =>{
    return(
        <>{
            PrivacyData.map((item, i)=>(
                <PageFormatCard {...item} key={i} />
            ))
        }</>
    )
}

export const AppealProcess = () =>{
    <>{
        processData.map((item, i)=>(
            <PageFormatCard {...item} key={i} />
        ))
    }</>
}

export const TermsAndConditions = () =>{
    <>{
        ConditionData.map((item, i)=>(
            <PageFormatCard {...item} key={i} />
        ))
    }</>
}

export const ComplaintProcess = () =>{
    <>{
        complaintsData.map((item, i)=>(
            <PageFormatCard {...item} key={i} />
        ))
    }</>
}