import axios from "axios";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Header } from "../TableHeader";
import { StatBox } from "../StatBox";
import {LineChart} from "../LineChart";
import {BarChart} from "../BarChart";
import {PieChart} from "../PieChart";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const Dashboard = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const colors = tokens(theme.palette.mode);
    const [ companyData, setCompanyData ] = useState({
        contactData: [],
        noticeData: [],
    });
    const [ noticeData, setNoticeData ] = useState([]);
 
    const dashboardAPI = async () =>{
        // fetch contact data
        await axios.get(`/api/contact`)
            .then((response)=>{
                setCompanyData({
                    contactData: response.data
                })
            })
            .catch((err)=>{
                console.log("Problem with network! Try again later.");
            })
        // fetch notice data
        await axios.get(`/api/notice`)
            .then((response)=>{
                setNoticeData(response.data);
            })
            .catch((err)=>{
                console.log("Problem with network! Try again later.");
            })
    }

    const handleNavigation = (link) => {
        return navigate(`/${link}`)
    }

    useEffect(()=>{
        dashboardAPI();
    },[])

    return (
        <Box m="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="AYOPARK" subtitle="Welcome to the Admin panel." />
        </Box>
        {/* GRID & CHARTS */}
        <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoColumns='colum'
            gridAutoRows="140px"
            gap="20px"
        >
            {/* THIS IS THE MENU ROW */}
            <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[700]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={()=>handleNavigation('account/contact')}
                sx={{ cursor: 'pointer' }}>
                <StatBox
                    title={'5'}
                    subtitle="All Contacts"
                    progress="0.75"
                    increase="+14%"
                    icon={'source'}
                />
            </Box>
            <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[700]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={()=>handleNavigation('account/notice')}
                sx={{ cursor: 'pointer' }}>
                <StatBox
                    title={'5'}
                    subtitle="All PCNs"
                    progress="0.50"
                    increase="+21%"
                    icon={'rights'}/>
            </Box>
            <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[700]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={()=>handleNavigation('account/appeal')}
                sx={{ cursor: 'pointer' }}>
                <StatBox
                    title={'15' }
                    subtitle="All Appeals"
                    progress="0.30"
                    increase="+5%"
                    icon={'user'}/>
            </Box>
            <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[700]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={()=>handleNavigation('account/user')}
                sx={{ cursor: 'pointer' }}>
                <StatBox
                    title={'10'}
                    subtitle="All Users"
                    progress="0.80"
                    increase="+43%"
                    icon={'plant'}/>
            </Box>
        {/* PCN & APPEAL STATISTICS */}
        <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={colors.primary[700]}>
            {/* LINE CHART STATISTICS */}
            <Box
                mt="25px"
                p="0 30px"
                display="flex "
                justifyContent="space-between"
                alignItems="center">
                <Box>
                    <IconButton>
                        <i class="pi pi-download" styles="font-size: 2rem"></i>
                    </IconButton>
                </Box>
            </Box>
            <Box height="250px" m="-20px 0 0 0">
                <LineChart />
            </Box>
        </Box>
        {/* PCN TRANSACTION DETAILS */}
        <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[700]}
            overflow="auto">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.orangeAccent[500]}`}
                colors={colors.primary[100]}
                p="15px">
                <Typography color={colors.primary[100]} variant="h5" fontWeight="600">
                    PCN TRANSACTIONS
                </Typography>
            </Box>
            {noticeData.map((transaction, i) => (
            <Box
                key={`${transaction.id}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.orangeAccent[500]}`}
                p="15px">
                <Box>
                    <Typography
                        color={colors.orangeAccent[400]}
                        variant="h5"
                        fontWeight="600">
                        {transaction.PCNNumber} 
                    </Typography>
                    <Typography color={colors.primary[100]}>
                        {transaction.VRPNumber}
                    </Typography>
                </Box>
                <Box color={colors.primary[100]}>{transaction.name}</Box>
                <Box
                    backgroundColor={colors.orangeAccent[700]}
                    color={'white'}
                    p="5px 10px"
                    borderRadius="4px">
                    Data: {transaction.arrivingDate} to {transaction.departureDate}
                </Box>
            </Box>
          ))}
        </Box>

    {/* USER DISTRIBUTION  */}
        <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[700]}
            p="30px">
                <Typography variant="h5" fontWeight="600">
                    User Types Distribution # </Typography>
                <PieChart />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px">
            <Typography></Typography>
          </Box>
        </Box>
        {/* Water Quantity Data BarChart*/}
        <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[700]}>
            <Typography
                variant="h5"
                fontWeight="600"
                sx={{ padding: "30px 30px 0 30px" }}>Number of PCNs ( Monthly )</Typography>
            <Box height="250px" mt="-20px">
                <BarChart />
            </Box>
        </Box>
        {/* Geographical Data */}
        <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[700]}
            padding="30px">
            <Typography
                variant="h5"
                fontWeight="600"
                sx={{ marginBottom: "15px" }}></Typography>
            <Box height="200px">
                {/* A chart with the map of the water basins */}
            </Box>
        </Box>
      </Box>
    </Box>
  );
};