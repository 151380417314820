import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CollapsibleTable from "./notice/Table";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import GarageIcon from '@mui/icons-material/Garage';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const ServiceComponents = () =>{
  const [ noticeData, setNoticeData ] = useState([]);
  const navigate = useNavigate();
  
    const handleNavigate = (link) =>{
      navigate(link);
    }
  return(
    <div className="container-fluid">
        {/* notification section */}
        <div className="row mb-2">
          {/* parking charge notice notifications */}
          <div className="col d-flex justify-content-center align-items-center mt-3 mb-3">
            <div className="d-flex justify-content-center align-items-center p-2" style={{
                width: '16rem',
                height: '8rem',
                boxShadow: 'inset .05rem .05rem .15rem',
                borderRadius: '.25rem'
              }}>
                <div className="col text-center p-3">
                  <CameraEnhanceIcon sx={{ fontSize: '4rem', transition: 'all 200ms', cursor: 'pointer', '&:hover':{ transform: 'scale(1.3)'}}}/>
                  <p>PCNs</p>
                </div>
                <div className="col" style={{ fontSize: '2rem'}}>#{'02'}</div>
            </div>
          </div>
          {/* appeal notifications */}
          <div className="col d-flex justify-content-center align-items-center mt-3 mb-3">
            <div className="d-flex justify-content-center align-items-center" style={{
                width: '16rem',
                height: '8rem',
                boxShadow: '.1rem .1rem .25rem',
                borderRadius: '.25rem'
              }}>
                <div className="col text-center p-3">
                  <AutoModeIcon sx={{ fontSize: '4rem', transition: 'all 200ms', cursor: 'pointer', '&:hover':{ transform: 'rotateZ(270deg)'}}}/>
                  <p>Appeals</p>
                </div>
                <div className="col" style={{ fontSize: '2rem'}}>#{'05'}</div>
              </div>
          </div>
          {/* carpark notifications */}
          <div className="col d-flex justify-content-center align-items-center mt-3 mb-3">
              <div className="d-flex justify-content-center align-items-center p-2" style={{
                  width: '16rem',
                  height: '8rem',
                  boxShadow: ' .05rem .05rem .15rem',
                  borderRadius: '.25rem'
                }}>
                <div className="col text-center p-3">
                  <GarageIcon sx={{ fontSize: '4rem', transition: 'all 200ms', cursor: 'pointer', '&:hover':{ transform: 'scale(1.3)'}}}/>
                  <p>Car Parks</p>
                </div>
                <div className="col" style={{ fontSize: '2rem'}}>#{'03'}</div>
              </div>
          </div>
          {/* payment notifications */}
          <div className="col d-flex justify-content-center align-items-center mt-3 mb-3">
              <div className="d-flex justify-content-center align-items-center p-2" style={{
                  width: '16rem',
                  height: '8rem',
                  boxShadow: 'inset .05rem .05rem .15rem',
                  borderRadius: '.25rem'
                }}>
                <div className="col text-center p-3">
                  <ShoppingCartCheckoutIcon sx={{ fontSize: '4rem', transition: 'all 200ms', cursor: 'pointer', '&:hover':{ transform: 'translateX(2rem)'}}}/>
                  Payments
                </div>
                <div className="col" style={{ fontSize: '2rem'}}>#{'03'}</div>
              </div>
          </div>
        </div>
        {/* records */}
        <div className="row mb-3">
          <div className="col">
            <h5 className="text-center p-2 m-1">Parking Charge Notice Records</h5>
            <CollapsibleTable notice={noticeData} />
          </div>
        </div>
    </div>
  )
}

function SearchNoticeComponent(){
    const [ noticeData, setNoticeData ] = useState([]);
    const [ searchItem, setSearchItem ] = useState('');
    const [ feedback, setFeedBack ] = useState(false);
    const navigate = useNavigate();
  
    const handleNavigate = (link) =>{
      navigate(link);
    }
  
    const noticeAPI = async () =>{
      await axios.get(`/api/notice/search/${searchItem}`)
        .then((response)=>{
          if(!response.data) return 
          setNoticeData(response.data);
          setFeedBack(true);
        })
        .catch((err)=>{
          console.log(err.message);
        })
    }
  
    const handleChange = async (e) =>{
      setFeedBack(false);
      setSearchItem(e.target.value);
    }
  
    const handleSearch = (e) =>{
      e.preventDefault();
      if(searchItem == '')return
      noticeAPI();
    }
  
    return(
      <div className="row">
        {/* search component */}
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Collapse id="navbarScroll">
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                  onChange={handleChange}
                  aria-placeholder="vehicle registration"
                />
                <Button variant="outline-warning" onClick={handleSearch}>Search</Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* search result component */}
        <Card>
          <Card.Body>
            {feedback ? <CollapsibleTable notice={noticeData} /> : ""}
            <Card.Title style={{ fontWeight: '700', fontSize: '2rem'}}>Landlords</Card.Title>
            <Card.Text style={{ maxWidth: '40rem', lineHeight: '1.5'}}>
                Welcome to the Parking Charge Notice Management Panel. Here, you can efficiently manage and resolve any parking charge notices you may have received. Please ensure you follow all instructions carefully to address your notices promptly. If you need assistance, our customer support team is available to help. Thank you for using our service.
            </Card.Text>
            <Button 
                variant="secondary" 
                onClick={()=>handleNavigate('/account/landlord')}>Go To Dashboard</Button>
            {/* dashboard details */}
            <ServiceComponents />
          </Card.Body>
        </Card>
      </div>
    )
}

export const LandlordDB = () =>{
    return(
        <div className="container">
            <h4 className="m-2 text-uppercase text-center text-warning p-2">
              Welcome to the Landlord Portal
            </h4>
            <SearchNoticeComponent />
        </div>
    )
}