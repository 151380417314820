export const servicesD = [
    {
        img: 'pi-briefcase',
        title: 'Are You A Landlord Or Land Owner?',
        text: 'Are you seeking comprehensive parking enforcement solution to effectively address car park abuse? Look no further. We are a distinguished UK-based company renowned for our expertise in self-ticketing management.',
        user: "i'm a landlord"
    },
    {
        img: 'pi-car',
        title: 'Got A Parking Charge Notice?',
        text: 'You have recently received a parking charge notice through postal correspondence. Please click on the login button above, which will enable you to either make the payment, file an appeal, or transfer the notice to the appropriate recipient.',
        user: "i'm a client"
    }
]