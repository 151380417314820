import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CollapsibleTable from "./Table";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function SearchNoticeComponent(){
  const [ noticeData, setNoticeData ] = useState([]);
  const [ searchItem, setSearchItem ] = useState('');
  const [ feedback, setFeedBack ] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = (link) =>{
    navigate(link);
  }

  const noticeAPI = async () =>{
    await axios.get(`/api/notice/search/${searchItem}`)
      .then((response)=>{
        if(!response.data) return 
        setNoticeData(response.data);
        setFeedBack(true);
      })
      .catch((err)=>{
        console.log(err.message);
      })
  }

  const handleChange = async (e) =>{
    setFeedBack(false);
    setSearchItem(e.target.value);
  }

  const handleSearch = (e) =>{
    e.preventDefault();
    if(searchItem == '')return
    noticeAPI();
  }

  return(
    <div className="row">
      {/* search component */}
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Collapse id="navbarScroll">
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                onChange={handleChange}
                aria-placeholder="vehicle registration"
              />
              <Button variant="outline-warning" onClick={handleSearch}>Search</Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* search result component */}
      <Card>
        <Card.Body>
          {feedback ? <CollapsibleTable notice={noticeData} /> : ""}
          <Card.Title>PCN Management Panel</Card.Title>
          <Card.Text>
            Welcome to the Parking Charge Notice(PCN) Management Panel for Administrators. This interface allows you to efficiently oversee and manage all parking charge notices issued within our facility. Please ensure you follow the established protocols for reviewing, processing, and resolving notices. If you encounter any issues or require assistance, our support team is available to help. Thank you for your dedication to maintaining an orderly and efficient parking environment.
          </Card.Text>
          <Button 
              variant="warning" 
              onClick={()=>handleNavigate('/account/admin/notice')}>Go To PCN Form</Button>&nbsp;&nbsp;&nbsp;
          <Button 
              variant="secondary" 
              onClick={()=>handleNavigate('/account')}>Go To Dashboard</Button>
        </Card.Body>
      </Card>
    </div>
  )
}

export const NoticeDB = () => {
    const [ noticeData, setNoticeData ] = useState([]);

    const noticeAPI = async () =>{
      await axios.get(`/api/notice`)
        .then((response)=>{
          setNoticeData(response.data);
        })
        .catch((err)=>{
          console.log("Problem with network! Try again later.");
        })
    }

    useEffect(()=>{
      noticeAPI();
    },[])

    return(
        <div>
            <SearchNoticeComponent />
            <CollapsibleTable notice={noticeData} />
        </div>
    )
}