import Logo from '../../assets/wlogo.svg';
import { AppealForm } from '../structure/RenderService';

export const Appeal = () => {
    return (
        <div className='m-2'>
            {/* contact content */}
            <div className='m-1'>
                <div className='w-100 text-center' style={{ height: '10rem'}}>
                    <img
                        className="img-fluid rounded d-block w-100 h-100"
                        loading='lazy'
                        src={Logo}
                        alt="ayopark logo"
                    />
                </div>
                <AppealForm />
            </div>
        </div>
    )
}