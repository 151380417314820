import * as React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function createData(id, name, amount, role, address, location, VRPNumber, PCNNumber, email, phone, arrivingTime, arrivingDate, departureTime, departureDate, file) {
  return {
    id,
    name,
    amount,
    role,
    address,
    location,
    VRPNumber,
    PCNNumber,
    email,
    phone,
    arrivingTime,
    arrivingDate,
    departureTime,
    departureDate,
    file: {
        type: "",
        data: [],
    }
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.PCNNumber}
        </TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">{row.VRPNumber}</TableCell>
        <TableCell align="right">
          {
            {
              'driver': <span className='bg-primary p-2' style={{ width: '15rem'}}>Driver</span>,
              'keeper': <span className='bg-success p-2' style={{ width: '15rem'}}>Keeper</span>,
              'hirer': <span className='bg-danger p-2' style={{ width: '15rem'}}>Hirer</span>,
            }[row.role]
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                File
              </Typography>
              <Table size="small" aria-label="file details">
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>File</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.arrivingTime}&nbsp;-&nbsp;{row.departureTime}</TableCell>
                    <TableCell>{row.arrivingDate}&nbsp;:&nbsp;{row.departureDate}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.file ? row.file.data : 'N/A'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    VRPNumber: PropTypes.string.isRequired,
    PCNNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    arrivingTime: PropTypes.string.isRequired,
    arrivingDate: PropTypes.string.isRequired,
    departureTime: PropTypes.string.isRequired,
    departureDate: PropTypes.string.isRequired,
    file: PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.string,
    }),
  }).isRequired,
};

const rows = [
  createData(1, '', '', '', '', '', ''),
];

export default function CollapsibleTable({notice}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>PCN (Reference)</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Vehicle&nbsp;(Registration)</TableCell>
            <TableCell align="right">Role&nbsp;(Type of PCN)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notice !== null | notice.length <= 0 ? notice.map((row)=>(
            <Row key={row.id} row={row} />
          )) : (
            <p>Parking Charge Notice database empty!</p>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}