import { styled } from '@mui/material/styles'
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import Wlogo from '../../assets/wlogo.svg';

export const About = () => {
  const questions = [
    {
      question: ' What is ANPR?',
      answer: 'ANPR stands for Automatic Number Plate Recognition. It is a technology used in the UK for monitoring and managing vehicle movements in various applications, including parking enforcement. ANPR systems capture and record vehicle registration numbers automatically, aiding in the enforcement of parking regulations.'
    },
    {
      question: 'Who is Debt Recovery Plus?',
      answer: 'Debt Recovery Plus is a private debt collection agency often employed by parking management companies to pursue unpaid parking charges. If you receive a notice from Debt Recovery Plus, it means the parking charge may have escalated, and they are attempting to recover the outstanding amount.'
    },
    {
      question: 'Could I get a county court judgement?',
      answer: 'Yes, if a parking charge remains unpaid and the parking management company decides to pursue legal action, they may apply to the county court for a judgment against you. This could have serious consequences, including a negative impact on your credit rating.'
    },
    {
      question: 'How can I appeal?',
      answer: 'Kindly refer to your original parking ticket or log in to our website at ayopark.com to determine your eligibility for filing an appeal.'
    },
    {
      question: 'Can I set up a payment plan?',
      answer: 'Yes, you may be eligible to establish a convenient payment plan to address any outstanding parking charges. We recommend reaching out to the ayopark customer service to initiate discussions and set up a personalized payment arrangement. Taking proactive steps can help you explore feasible options and prevent any potential escalation. We recommend reaching out to us begin the process and find a solution that works for you. (attach link to the page)'
    },
    {
      question: 'What is self-ticketing?',
      answer: 'Self-ticketing refers to the practice of property owners or managers issuing parking tickets themselves without the involvement of external parking enforcement companies. This is common on private land where the property owner has established and enforces their own parking rules.'
    },
    {
      question: 'What is Parking on Private Land Appeal?',
      answer: 'Parking on Private Land Appeal (POPLA) is an independent appeals service used by certain parking management companies. If you are dissatisfied with the outcome of your initial appeal with the parking operator, you may have the option to escalate your case to POPLA for an independent review.'
    },
    {
      question: 'Who is the BPA?',
      answer: 'The BPA (British Parking Association) is a trade association representing the interests of the parking and traffic management industry in the UK. Many parking management companies are members of the BPA, and they are expected to adhere to a code of practice that outlines fair and transparent practices.'
    },
    {
      question: 'Do I need to pay my parking charge?',
      answer: 'If you have received a valid parking charge and it is enforceable, it is generally advisable to pay it to avoid further consequences such as additional charges, legal action, or damage to your credit rating. However, if you believe the charge is unjust, you may explore the appeals process to contest it.'
    }
  ];

  const StyledBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    padding: '1rem',
    color: '#fff'
  });

  const StyledTypography = styled(Typography)({
    fontWeight: 'bold'
  });

  return (
    <div>
        <StyledBox>
        <div className='w-100 text-center' style={{
              height: '8rem'
          }}>
              <img
              className="img-fluid rounded d-block w-100 h-100"
              loading='lazy'
              src={Wlogo}
              alt="ayopark company logo"
              />
          </div>
        <StyledTypography variant="h4">Frequently Asked Questions</StyledTypography>
        {questions.map((q, i) => (
            <Accordion key={i} sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<i class="pi pi-sort-amount-up" style={{fontSize: '2rem'}}></i>} aria-controls={`panel${i}-content`} id={`panel${i}-header`}>
                <Typography>{q.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{q.answer}</Typography>
            </AccordionDetails>
            </Accordion>
        ))}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '1rem' }}>
            <i class="pi pi-question-circle" style={{
                fontSize: '2rem'
            }}></i>
            <Typography variant="body2">
              <a 
                href="/contact"
                style={{
                  textDecoration: 'none',
                  color: '#fff'
                }}>
                contact us
              </a>
            </Typography>
        </Box>
        </StyledBox>
    </div>
  );
};
