import { AppealProcess } from '../services/documents'
import Wlogo from '../../assets/wlogo.svg'

export const Process = () => {

    return (
        <div>
            <div className="row" style={{
                width: '100vw',
                marginTop: '60px'
            }}>
                <div className="col-sm-7 m-1 p-2 text-center">
                    <img 
                        className="img-fluid rounded"
                        loading='lazy'
                        src={Wlogo}
                        alt="company logo"
                        style={{
                            height: '200px'
                        }}
                    />
                </div>
                <div className="col-sm-3 m-1 p-2 lh-lg text-center">
                    Please post to this address: <br />
                    Parkade Enforcement Limited <br />
                    trading as Ayopark Enforcement, <br />
                    Unit 65928, <br />
                    PO BOX 6945, <br />
                    London, W1A 6US <br />
                </div>
            </div>
            <h2 className="text-center m-2 p-2">Appeal Process</h2>
            <AppealProcess />
        </div>
    )
}