import { Spinner } from 'react-bootstrap';

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export const validatePassword = (pwd) => {
    if(pwd.length >= 8) return true;
    return false
}

export const fetchSubLoader = () =>{
    return (
        <>
          <Spinner animation="grow" variant="success" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="info" />
          <Spinner animation="grow" variant="light" />
        </>
    );
}

export const fetchUser = () =>{
    const user = localStorage.getItem('user');
    const data = JSON.parse(user);
    return data ? data.user : null;
};