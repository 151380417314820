import * as React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Buffer } from 'buffer';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function createData(id, name, email, phone, address, message, file) {
  return {
    id,
    name,
    email,
    phone, 
    address, 
    message, 
    file: {
        type: "",
        data: [],
    }
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.email}</TableCell>
        <TableCell align="right">{row.phone}</TableCell>
        <TableCell align="right">{row.address}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                File
              </Typography>
              <Table size="small" aria-label="file details">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell align="right">Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.file ? row.file.type : 'N/A'}
                    </TableCell>
                    <TableCell>{row.file ? (<img src={Buffer.from(row.file.data, 'binary').toString('base64')} alt='contact file' style={{ width: '100px', height: '100px'}}/>) : 'N/A'}</TableCell>
                    <TableCell align="right">{row.message}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    file: PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.string,
    }),
  }).isRequired,
};

const rows = [
  createData(1, '', '', '', '', '', ''),
];

export default function CollapsibleTable({notice}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Phone&nbsp;(+44)</TableCell>
            <TableCell align="right">Address&nbsp;(UK)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notice !== null | notice.length <= 0 ? notice.map((row)=>(
            <Row key={row.id} row={row} />
          )) : (
            <p>Parking Charge Notice database empty!</p>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}