import { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Button from '@mui/material/Button';

const ListItemIcon = ({item}) => {
    const values = 'Earn up to €50 per paid parking charge';

    return (
        <div className='pb-1'>
        {
            item !== values ? <div> <i class="pi pi-check-circle bg-success rounded-circle p-1"></i> {item}</div> : <div> <i class="pi pi-times-circle bg-danger rounded-circle p-1"></i> {item}</div>
        }
        
        </div>
    )
}

const ListItemIcon2 = ({item}) => {
    const values = 'No Exit Fee Guarantee during contract';
    const values2 = 'Cancel a PCN within 28 days';
    const values3 = 'Complimentary ANPR system';

    return (
        <div className='pb-1 pt-1'>
        {
            item === values || item === values2 || item === values3 ? <div> <i class="pi pi-times-circle bg-danger rounded-circle p-1"></i> {item}</div> : <div> <i class="pi pi-check-circle bg-success rounded-circle p-1"></i> {item}</div>
        }
        
        </div>
    )
}

const SubCard = ({title, data, variant, cardID}) => {
    const navigate = useNavigate()
    const title11 = "Enforcement for a cause";

    const handleButton = () => {
        navigate('/contact')
    }

    return (
      <>
          <Card
            bg={cardID === 1 ? 'dark' : variant}
            key={variant}
            text={variant === 'light' ? 'dark' : 'white'}
            style={{ width: '22rem', height: '30rem' }}
            className="m-2 p-2"
          >
            <Card.Header>Subscription Plan</Card.Header>
            <Card.Body>
              <Card.Title>{title} </Card.Title>
              <Card.Text className='lh-lg'>
                {
                    title === title11 ? <div>{
                        data.map((item, k) =>(<ListItemIcon key={k} item={item} />))
                    }</div> : <div>{
                        data.map((item, k) => (<ListItemIcon2 key={k} item={item} />))
                    }</div>
                }
              </Card.Text>
              <Card.Footer>
                <Button variant='warning' onClick={handleButton} className='bg-warning text-light text-center'>
                    CHOOSE PLAN
                </Button>
              </Card.Footer>
            </Card.Body>
          </Card>
      </>
    );
}

export const SubscriptionCard = ({Arr}) => {
    const bgColors = [
      'info',
      'secondary',
      'light',
      'dark',
    ];
    let bgColorsLen = bgColors.length;
    let randomNum = Math.floor(Math.random() * bgColorsLen);
    let variant = bgColors[randomNum];

    return (
        <div className='d-flex justify-content-center align-items-center m-2 flex-wrap' style={{ lineHeight: '1.5'}}>
            {
                Arr.map((item, k)=>(
                    <SubCard key={k} {...item} cardID={k} variant={variant} />
                ))
            }
        </div>
    )
}

export const ServiceCard = ({ Arr }) => {
  const navigate = useNavigate()

  const handleButton = () =>{
    const link = 'contact';
    return navigate(`/${link}`);
  }

  return (
    <Row  
      md={2} 
      className="g-1 m-3 p-2 d-flex justify-content-center align-items-center flex-wrap" >
      {Arr.map((value, idx) => (
          <Card 
            key={idx}
            className='d-flex justify-content-center align-items-center flex-wrap flex-column'
            style={{ 
              maxWidth: '400px',
              height: '23rem',
              margin: '.5rem .8rem'}}
          >
            <Card.Header className='icon-lg bg-warning rounded-3 text-white'>
              <i className={`pi ${value.img} p-3`} style={{ fontSize: '5rem', fontWeight: '500'}}></i>
            </Card.Header>
            <Card.Body className='text-center'>
              <Card.Title className='m-1'>{value.title}</Card.Title>
              <Card.Text className="lh-lg">
                {value.text}
              </Card.Text>
            </Card.Body>
          </Card>
      ))}
    </Row>
  );
}

export const BenefitsCard = ({ Arr }) =>{
  return(
    <>
      {
        Arr.map((value, k)=>( <BenefitsSection key={k} {...value} />))
      }
    </>
  )
}

export const BenefitsSection = ({ title, list }) =>{
  const textItem = ({item}) =>{
    console.log('print item: '+item);
    return (
      <div className="user_text mb-3">
          <p className="mbr-fonts-style display-7">
              <strong>
                {item}
              </strong>
          </p>
      </div>
    )
  }

  return (
    <div className="text-center text-lg-start bg-body-tertiary text-muted">
      <div className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div id="carouselContent" className="carousel slide" data-ride="carousel">
          <div className="container">
            <div className="carousel-inner text-center text-white m-auto" role="listbox">
              <div className="carousel-item active text-center p-4">
                {
                  list.map((value, k) => (<textItem key={k} item={value.item} />))
                }
              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselContent" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselContent" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export const TextCarousel = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const carouselItems = [
        'when the parking charge is settled within 14 days at £60, the landlord is entitled to £10', 
        'If payment is made within 28 days at £100, the landlord will receive £20.',
        'In the event of payment at debt recovery, amounting to £170, the landlord will receive £30.',
        'Should payment be made through court or enforcement, the landlord will receive £50.'
    ];

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} interval={2500}>
            {carouselItems.map((item, idx) => (
                <Carousel.Item key={idx}>
                    <div className="d-flex justify-content-center align-items-center" style={{height: '300px'}}>
                        <p className='lh-lg p-1 h3 text-center'>{item}</p>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};