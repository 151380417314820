import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import CollapsibleTable from "./Table";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function SearchNoticeComponent(){
    const [ data, setData ] = useState([]);
    const [ searchItem, setSearchItem ] = useState('');
    const [ feedback, setFeedBack ] = useState(false);
    const navigate = useNavigate();
  
    const handleNavigate = (link) =>{
      navigate(link);
    }
  
    const serverAPI = async () =>{
      await axios.get(`/api/notice/search/${searchItem}`)
        .then((response)=>{
          if(!response.data) return 
          setData(response.data);
          setFeedBack(true);
        })
        .catch((err)=>{
          console.log(err.message);
        })
    }
  
    const handleChange = async (e) =>{
      setFeedBack(false);
      setSearchItem(e.target.value);
    }
  
    const handleSearch = (e) =>{
      e.preventDefault();
      if(searchItem == '')return
      serverAPI();
    }
  
    return(
      <div className="row">
        {/* search component */}
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Collapse id="navbarScroll">
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="search"
                  onChange={handleChange}
                  aria-placeholder="user email"
                />
                <Button variant="outline-warning" onClick={handleSearch}>Search</Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* search result component */}
        <Card>
          <Card.Body>
            {feedback ? <CollapsibleTable notice={data} /> : ""}
            <Card.Title>Appeal Management Panel</Card.Title>
            <Card.Text>
                Welcome to the Parking Charge Notice Appeal Management Panel. This interface allows you to efficiently handle and process appeals related to parking charge notices. Please ensure you follow all guidelines and protocols for reviewing and resolving appeals. If you need any assistance, our support team is available to help. Thank you for your dedication to fair and effective appeal management.
            </Card.Text>
            <Button 
                variant="warning" 
                onClick={()=>handleNavigate('/account/admin/user')}>Check Appeal Progress</Button>&nbsp;&nbsp;&nbsp;
            <Button 
                variant="secondary" 
                onClick={()=>handleNavigate('/account')}>Go To Dashboard</Button>
          </Card.Body>
        </Card>
      </div>
    )
}

export const AppealDB = () => {
    return(
        <div>
            <SearchNoticeComponent />
            <CollapsibleTable />
        </div>
    )
}