import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";


// color design tokens
export const tokens = (mode) => ({
    ...(mode === "dark" 
        ? {
            primary: {
                100: "#d6d7d8",
                200: "#adb0b1",
                300: "#83888a",
                400: "#5a6163",
                500: "#31393c",
                600: "#272e30",
                700: "#1d2224",
                800: "#141718",
                900: "#0a0b0c"
            },
            indigoAccent: {
                100: "#d3e4ff",
                200: "#a6c8ff",
                300: "#7aadff",
                400: "#4d91ff",
                500: "#2176ff",
                600: "#1a5ecc",
                700: "#144799",
                800: "#0d2f66",
                900: "#071833"
            },
            blueAccent: {
                100: "#d6ecff",
                200: "#add9fe",
                300: "#85c7fe",
                400: "#5cb4fd",
                500: "#33a1fd",
                600: "#2981ca",
                700: "#1f6198",
                800: "#144065",
                900: "#0a2033"
            },
            yellowAccent: {
                100: "#fff4d9",
                200: "#feeab3",
                300: "#fedf8c",
                400: "#fdd566",
                500: "#fdca40",
                600: "#caa233",
                700: "#987926",
                800: "#65511a",
                900: "#33280d"
            },
            orangeAccent: {
                100: "#fdead3",
                200: "#fcd6a7",
                300: "#fac17c",
                400: "#f9ad50",
                500: "#f79824",
                600: "#c67a1d",
                700: "#945b16",
                800: "#633d0e",
                900: "#311e07"
            },
        } : {
            primary: {
                100: "#0a0b0c",
                200: "#141718",
                300: "#1d2224",
                400: "#272e30",
                500: "#31393c",
                600: "#5a6163",
                700: "#83888a",
                800: "#adb0b1",
                900: "#d6d7d8",
            },
            indigoAccent: {
                100: "#071833",
                200: "#0d2f66",
                300: "#144799",
                400: "#1a5ecc",
                500: "#2176ff",
                600: "#4d91ff",
                700: "#7aadff",
                800: "#a6c8ff",
                900: "#d3e4ff",
            },
            blueAccent: {
                100: "#0a2033",
                200: "#144065",
                300: "#1f6198",
                400: "#2981ca",
                500: "#33a1fd",
                600: "#5cb4fd",
                700: "#85c7fe",
                800: "#add9fe",
                900: "#d6ecff",
            },
            yellowAccent: {
                100: "#33280d",
                200: "#65511a",
                300: "#987926",
                400: "#caa233",
                500: "#fdca40",
                600: "#fdd566",
                700: "#fedf8c",
                800: "#feeab3",
                900: "#fff4d9",
            },
            orangeAccent: {
                100: "#311e07",
                200: "#633d0e",
                300: "#945b16",
                400: "#c67a1d",
                500: "#f79824",
                600: "#f9ad50",
                700: "#fac17c",
                800: "#fcd6a7",
                900: "#fdead3",
            },
        })
})

// theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode);

    return {
        palette: {
            mode: mode,
            ...(mode === 'dark'
                ? {
                    primary: {
                        main: colors.primary[800],
                    },
                    secondary: {
                        main: colors.yellowAccent[500],
                    },
                    neutral: {
                        dark: colors.indigoAccent[700],
                        main: colors.indigoAccent[500],
                        light: colors.indigoAccent[100]
                    },
                    background: {
                        default: colors.primary[500],
                    }
                }:{
                    primary: {
                        main: colors.primary[100],
                    },
                    secondary: {
                        main: colors.yellowAccent[500],
                    },
                    neutral: {
                        dark: colors.indigoAccent[700],
                        main: colors.indigoAccent[500],
                        light: colors.indigoAccent[100]
                    },
                    background: {
                        default: "#fcfcfc",
                    }  
                }
            )
        },
        typography: {
            fontFamily: ["Source Sans 3", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Source Sans 3", "sans-serif"].join(","),
                fontSize: 40, 
            },
            h2: {
                fontFamily: ["Source Sans 3", "sans-serif"].join(","),
                fontSize: 32, 
            },
            h3: {
                fontFamily: ["Source Sans 3", "sans-serif"].join(","),
                fontSize: 24, 
            },
            h4: {
                fontFamily: ["Source Sans 3", "sans-serif"].join(","),
                fontSize: 20, 
            },
            h5: {
                fontFamily: ["Source Sans 3", "sans-serif"].join(","),
                fontSize: 16, 
            },
            h6: {
                fontFamily: ["Source Sans 3", "sans-serif"].join(","),
                fontSize: 14, 
            }
        }
    }
}

// context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => {}
});

export const useMode = () => {
    const [mode, setMode] = useState("dark");

    const colorMode = useMemo(
        ()=>({
            toggleColorMode: () => setMode((prev)=>(prev === "light" ? "dark": "light")),
    }), []);

    const theme = useMemo(()=> createTheme(themeSettings(mode)), [mode])
    return [theme, colorMode];
}
