import Logo from '../../assets/wlogo.svg'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../services/middleware';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const ContactForm = () => {
    const [ formData, setFormData ] = useState({
      name: '',
      email: '',
      phone: '',
      message: '',
      file: null
    })
    const [ feedback, setFeedback ] = useState("");
    const navigate = useNavigate();
  
    const handleChange = (e) =>{
      const { name, value } = e.target;
      setFormData({...formData, [name]: value});
    }
    
    const IsFormValid = () =>{
      if(validateEmail(formData.email)) return false;
      return true;
    }
  
    const contactAPI = async () =>{
      axios.post(`/api/contact`, formData)
        .then((response)=>{
            setFormData({
              name: '', email: '', phone: '', message: '', file: null 
            });
            setFeedback("Your contact info is submitted!")
            setTimeout(()=>{
              navigate('/')
            }, 2000)
        })
        .catch((err)=>{        
            setFeedback("Problem with network! Try again later.");
            setTimeout(()=>{
              setFeedback("");
            }, 2000)
        })
    }
    
    const handleSubmit = (e) =>{
      e.preventDefault();
      contactAPI();
    }
  
    return (
        <Form className="m-1 p-1 form-container">
          {/* form title */}
          <div className='m-2 p-1 text-center text-capitalize'>
            <h3>contact us</h3>
          </div>
        {/* name input */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Name:</Form.Label>
          <Form.Control type="text" name='name' onChange={handleChange} placeholder="Enter your full name" />
        </Form.Group>
        {/* address input */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email:</Form.Label>
          <Form.Control type="email" name='email' onChange={handleChange} placeholder="Enter your email address" />
        </Form.Group>
        {/* phone input */}
        <Form.Group controlId="formBasicText" className="mb-3">
          <Form.Label>Phone:</Form.Label>
          <Form.Control type="text" name='phone' onChange={handleChange} placeholder="Enter your phone number" />
        </Form.Group>
        {/* message textarea */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Message:</Form.Label>
          <Form.Control as="textarea" name='message' onChange={handleChange} rows={3} placeholder='Enter your message'/>
        </Form.Group>
        {/* file input */}
        <Form.Group controlId="formFileMultiple" className="mb-3">
          <Form.Label>Choose File:</Form.Label>
          <Form.Control type="file" name='file' onChange={handleChange} multiple />
        </Form.Group>
        {/* user feedback */}
        <Form.Group className='text-center m-2'>
          <Form.Text className="text-warning" style={{
            fontSize: '1.25rem'
          }}>
            { feedback !== ""? feedback: ""}
          </Form.Text>
        </Form.Group>
        {/* submit contact btn */}
        <div className='text-center'>
          <Button variant="warning" 
            type="submit" 
            className='m-1 p-2 w-100 text-uppercase' 
            onClick={handleSubmit} disabled={IsFormValid()}>
            Submit
          </Button>
        </div>
      </Form>
    );
}

export const Contact = () => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center mb-3 p-2'>
            <div style={{ maxWidth: '45rem' }}>
                <div className='w-100 text-center' style={{ height: '8rem'}}>
                    <img
                        className="img-fluid rounded d-block w-100 h-100"
                        loading='lazy'
                        src={Logo}
                        alt="ayopark logo"
                    />
                </div>
                <ContactForm />
            </div>
        </div>
    )
}