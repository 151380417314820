import { Link } from 'react-router-dom';
import Logo from '../../assets/logo.svg';

export const RenderFooter = () =>{
    const scrollToTop = () =>{
        window.scrollTo(0, 0);
    }

    return (
        <>
            <footer className="text-center text-lg-start bg-body-tertiary text-muted foot-wrapper">
                {/* footer social media section */}
                <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                    <div className="me-5 d-none d-lg-block">
                        <span>Get connected with us on social networks:</span>
                    </div>
                    <div>
                        <Link to={'/'} className="me-4 text-reset">
                            <i className="pi pi-facebook"></i>
                        </Link>
                        <Link to={'/'} className="me-4 text-reset">
                            <i className="pi pi-twitter"></i>
                        </Link>
                        <Link to={'/'} className="me-4 text-reset">
                            <i className="pi pi-google"></i>
                        </Link>
                        <Link to={'/'} className="me-4 text-reset">
                            <i className="pi pi-instagram"></i>
                        </Link>
                        <Link to={'/'} className="me-4 text-reset">
                            <i className="pi pi-linkedin"></i>
                        </Link>
                    </div>
                </section>
                {/* footer newsletter section */}
                <section className="m-2 p-1">
                    <form action="">
                        <div className="row d-flex justify-content-center">
                        <div className="col-auto">
                            <p className="pt-2">
                            <strong>Register for newsletter:</strong>
                            </p>
                        </div>

                        <div className="col-md-5 col-12">
                            <div data-mdb-input-init className="form-outline mb-4">
                            <input type="email" id="form5Example26" className="form-control" placeholder="Enter your email address" />
                            </div>
                        </div>

                        <div className="col-auto">
                            <button data-mdb-ripple-init type="submit" className="btn btn-warning mb-4">
                                Subscribe
                            </button>
                        </div>
                        </div>
                    </form>
                    <div className="col-auto text-center">
                        <img
                            className="m-3 p-1"
                            src={Logo}
                            alt="operator logo"
                            style={{
                                height: '150px'
                            }}
                        />
                    </div>
                </section>
                {/* footer services section */}
                <section className="">
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            {/* footer company details */}
                            {/* <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    <i className="pi pi-home m-1" style={{
                                        fontSize: '2rem'
                                    }}></i> 
                                    <span className='m-1 p-2'>
                                        Ayopark Enforcement
                                    </span>
                                </h6>
                                <p className="lh-lg text-left">
                                    We operates within an intricate landscape, managing parking facilities on behalf of landlords in the UK, ensuring adherence to parking regulations and maintaining the smooth functioning of parking lots.
                                </p>
                            </div> */}
                            {/* footer services details */}
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Services
                                </h6>
                                <p>
                                    <Link to={'/payment'} 
                                        onClick={scrollToTop} 
                                        className='text-reset'>Pay Charge</Link>
                                </p>
                                <p>
                                    <Link to={'/contact'} 
                                        onClick={scrollToTop} 
                                        className='text-reset'>Make Inquiry</Link>
                                </p>
                                <p>
                                    <Link to={'/login'} 
                                        onClick={scrollToTop} 
                                        className='text-reset'>Register Car Park</Link>
                                </p>
                                <p>
                                    <Link to={'/appeal'} 
                                        onClick={scrollToTop} 
                                        className='text-reset'>Make PCN Appeal</Link>
                                </p>
                            </div>
                            {/* footer links details */}
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Useful links
                                </h6>
                                <p>
                                    <Link to={'/complaint'} 
                                        onClick={scrollToTop} 
                                        className='text-reset'>Complaints</Link>
                                </p>
                                <p>
                                    <Link to={'/policy'} 
                                        onClick={scrollToTop} 
                                        className='text-reset'>Privacy Policy</Link>
                                </p>
                                <p>
                                    <Link to={'/process'} 
                                        onClick={scrollToTop} 
                                        className='text-reset'>Appeal Process</Link>
                                </p>
                                <p>
                                    <Link to={'/condition'} 
                                        onClick={scrollToTop} 
                                        className='text-reset'>Terms & Conditions</Link>
                                </p>
                            </div>
                            {/* footer contact details */}
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                                <p>
                                    <i className="pi pi-home m-1"></i>
                                    Unit 65928, PO BOX 6945, London, W1A 6US
                                </p>
                                <p>
                                    <i className="pi pi-comment m-1"></i>
                                    info@ayopark.com
                                </p>
                                <p>
                                    <i className="pi pi-phone m-1"></i>
                                    08438865382
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* footer copyright section */}
                <div className="text-center p-4" styles="background-color: rgba(0, 0, 0, 0.05);">
                    All Rights Reserved 
                    <a className="text-reset m-1" href="https://ayopark.com/">Ayopark</a>
                    @ { new Date().getFullYear() }
                    <br />
                    <p className="text-center m-2 lh-lg">
                    Registered in England & Wales. Company No. 13412934 at 61 Bridge street, Kington, United Kingdom, HR53DJ. Data Protection No. ZB120482 (ICO). AyoPark Enforcement is a trading name of Parkade Enforcement Limited.
                    </p>
                </div>
            </footer>
        </>
    )
}