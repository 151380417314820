import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import CollapsibleTable from "./Table";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function SearchNoticeComponent(){
    const [ data, setData ] = useState([]);
    const [ searchItem, setSearchItem ] = useState('');
    const [ feedback, setFeedBack ] = useState(false);
    const navigate = useNavigate();
  
    const handleNavigate = (link) =>{
      navigate(link);
    }
  
    const serverAPI = async () =>{
      await axios.get(`/api/user/search/${searchItem}`)
        .then((response)=>{
          if(!response.data) return 
          setData(response.data);
          setFeedBack(true);
        })
        .catch((err)=>{
          console.log(err.message);
        })
    }
  
    const handleChange = async (e) =>{
      setFeedBack(false);
      setSearchItem(e.target.value);
    }
  
    const handleSearch = (e) =>{
      e.preventDefault();
      if(searchItem == '')return
      serverAPI();
    }
  
    return(
      <div className="row">
        {/* search component */}
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Collapse id="navbarScroll">
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="search"
                  onChange={handleChange}
                  aria-placeholder="user email"
                />
                <Button variant="outline-warning" onClick={handleSearch}>Search</Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* search result component */}
        <Card>
          <Card.Body>
            {feedback ? <CollapsibleTable notice={data} /> : ""}
            <Card.Title>Property Management Panel</Card.Title>
            <Card.Text>
                Welcome to the Property Management Panel. This interface allows landlords to efficiently manage their parking space locations, including adding, updating and removing locations. Please ensure you follow all security protocols and guidelines to maintain the integrity of location data. If you need any assistance, our support team is available to help. Thank you for your commitment to effective property management.
            </Card.Text>
            <Button 
                variant="warning" 
                onClick={()=>handleNavigate('/account/landlord/location')}>Add New Location</Button>&nbsp;&nbsp;&nbsp;
            <Button 
                variant="secondary" 
                onClick={()=>handleNavigate('/account')}>Go To Dashboard</Button>
          </Card.Body>
        </Card>
      </div>
    )
}

export const LocationDB = () => {
    const [locationData, setLocationData] = useState([]);

    const locationAPI = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        if (!storedUser) throw new Error('No user found in localStorage');
    
        const parsedUser = JSON.parse(storedUser);
        if (!parsedUser.token) throw new Error('No token found for user');
    
        const response = await axios.get('/api/user/fetch', {
          headers: {
            'Authorization': `Bearer ${parsedUser.token}`
          }
        });
    
        console.log("Response from user API: ", response.data);
      } catch (err) {
        console.error("Error fetching user data: ", err.message);
      }
    };
    
    useEffect(()=>{
      locationAPI();
    },[])

    return(
        <div>
            <SearchNoticeComponent />
            <CollapsibleTable notice={locationData}/>
        </div>
    )
}