import React from "react";

export const ChatBot = () => {
  const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_ID;

  React.useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;

    const script = document.createElement('script');
    script.src = 'https://client.crisp.chat/l.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div style={{
      position: 'fixed',
      right: 0,
      bottom: 0
    }}>
    </div>
  );
};