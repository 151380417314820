import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { RenderHeader } from '../components/structure/Header';
import { RenderFooter } from '../components/structure/Footer';
import { RenderRoutes } from '../components/structure/RenderNavigation';
import { ChatBot } from '../components/services/chatbot';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper = () => {
  const [user, setUser] = useState({
    name: 'guest',
    type: '',
    email: '',
    isAuthenticated: false,
  });

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser({ ...parsedUser.user, isAuthenticated: true });
        axios.defaults.headers.common['Authorization'] = `Bearer ${parsedUser.token}`;
      } catch (error) {
        console.error('Failed to parse user data:', error);
        localStorage.removeItem('user');
      }
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post('/api/user/login', { email, password });
      const { user: userData, token } = response.data;
      if (!token) throw new Error('No token received from server');

      setUser({ ...userData, isAuthenticated: true });
      localStorage.setItem(
        'user',
        JSON.stringify({
          user: userData,
          token,
        })
      );
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return 'success';
    } catch (error) {
      console.error('Login error:', error);
      throw new Error('Incorrect email or password');
    }
  };

  const logoutUser = async () => {
    try {
      await axios.get('/api/user/logout');
      return true;
    } catch (error) {
      console.error('Logout error:', error);
      return false;
    }
  };

  const logout = async () => {
    const isLoggedOut = await logoutUser();
    if (!isLoggedOut) {
      console.error('Failed to log out from server.');
      return;
    }

    localStorage.removeItem('user');
    setUser({
      name: 'guest',
      type: '',
      email: '',
      isAuthenticated: false,
    });
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      <RenderHeader />
      <RenderRoutes />
      <ChatBot />
      <RenderFooter />
    </AuthContext.Provider>
  );
};