import Logo from "../../../assets/wlogo.svg";
import { ParkingChargeNoticeForm } from "../../structure/RenderService";

export const NoticeForm = () =>{
    return(
        <>
            <div className='m-1'>
                <div className='w-100 text-center' style={{ width: '10rem', height: '10rem'}}>
                    <img
                        className="img-fluid rounded d-block w-100 h-100"
                        loading='lazy'
                        src={Logo}
                        alt="ayopark logo"
                    />
                </div>
                <ParkingChargeNoticeForm />        
            </div>
        </>
    )
}